<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('registrations')"
                        @filter-div-status="datatable.filterStatus=$event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('registrations')"
                              @filter-div-status="datatable.filterStatus=$event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :exportExcel="checkPermission('registration_excelexport')"
                              @exportExcel="exportExcel"
            >
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('legal_presence_type')">
                            <parameter-selectbox
                                code="legal_presence_types"
                                v-model="datatable.queryParams.filter.legal_presence_type">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm"
                                          v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('payment_status')">
                            <yes-no-selectbox v-model="datatable.queryParams.filter.payment_status"></yes-no-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('registration_status')">
                            <yes-no-selectbox
                                v-model="datatable.queryParams.filter.registration_status"></yes-no-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="6" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="6" xl="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="6" xl="4">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="6" xl="4">
                        <b-form-group :label="$t('registration_semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id"
                                                 :setActive="true"></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="6" xl="4">
                        <b-form-group :label="$t('acceptance_date')">
                            <select-date v-model="datatable.queryParams.filter.acceptance_date"></select-date>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import RegistrationService from "@/services/RegistrationService";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";

// Other
import qs from 'qs'
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

export default {
    components: {
        SemestersSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        YesNoSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('registrations')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                permission: "registration_update",
                                callback: (row) => {
                                   window.open('/registrations/update/register/' + row.id)
                                }
                            },
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: "registration_show",
                                callback: (row) => {
                                    window.open('/registrations/show/register/' + row.id)
                                }
                            },
                            {
                                text: this.$t('summary'),
                                class: 'ri-file-list-2-line',
                                permission: "registration_update",
                                callback: (row) => {
                                    window.open('/registrations/new/register/' + row.id + '/summary','_blank')
                                }
                            },
                            {
                                text: this.$t('payment_info'),
                                class: 'ri-money-dollar-circle-line',
                                permission: 'customer_customerpayments',
                                callback: (row) => {
                                    if (this.checkPermission('paymentplan_create')) {
                                        window.open('/customers/payments/registration/' + row.id,'_blank');
                                    } else {
                                        window.open('/customers/payments/view/registration/' + row.id),'_blank';
                                    }
                                }
                            },
                            {
                                text: this.$t("slots"),
                                class: "ri-list-check",
                                permission: "studentprogram_studentslot",
                                callback: (row) => {
                                    window.open("/student/programs/" + row.id + "/slots", '_blank')

                                },
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-5-line',
                                permission: 'registration_delete',
                                callback: (row) => {
                                    this.deleteRegister(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        sortable: false,
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester',
                        sortable: false,
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable: false,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.$t('faculty'),
                        field: 'faculty_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('program'),
                        field: 'program_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('registration_type'),
                        field: 'type_name',
                        tdClass: 'text-center',
                        sortable: false,
                    },
                    {
                        label: this.$t('payment_status'),
                        field: 'payment_status',
                        tdClass: 'text-center',
                        sortable: false,
                        formatFn: (row) => {
                            return row === 'E' ? this.$t('yes') : this.$t('no')
                        }
                    },
                    {
                        label: this.$t('registration_status'),
                        field: 'registration_status',
                        tdClass: 'text-center',
                        sortable: false,
                        formatFn: (row) => {
                            return row === 'H' ? this.$t('no') : this.$t('yes')
                        }
                    },
                    {
                        label: this.$t('legal_presence_type'),
                        field: 'legal_presence_type',
                        tdClass: 'text-center',
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        student_number: null,
                        name: null,
                        surname: null,
                        payment_status: null,
                        registration_status: null,
                        faculty_code: null,
                        program_code: null,
                        type: null,
                    },
                    sort: 'name',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                student_number: null,
                name: null,
                surname: null,
                payment_status: null,
                registration_status: null,
                faculty_code: null,
                program_code: null,
                type: null,
            };
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return RegistrationService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return RegistrationService.exportExcel(config)
                .then((response) => {
                    this._downloadFile(response, 'registrations.xlsx')
                })
        },
        deleteRegister(row) {
            if (this.checkPermission("registration_delete")) {
                if (row.payment_status == 'H') {
                    this.$swal.fire({
                        text: this.$t('registration_delete_text'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then(response => {
                        if (response.isConfirmed) {
                            RegistrationService.deleteRegister(row.id).then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows()
                            }).catch(e => {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            })
                        }
                    })
                } else {
                    this.$swal
                        .fire({
                            text: this.$t('registration_delete_payment_status_error'),
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonText: this.$t('ok'),
                        })
                }

            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
};
</script>

